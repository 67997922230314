import classNames from "classnames";
import {graphql} from "gatsby";
import * as React from "react";
import {ProductJsonLd} from "gatsby-plugin-next-seo";
import Layout from "../../../components/Layout/layout";
import Seo from "../../../components/SEO/SEO";
import TariffHero from "../../../components/Tariffs/Hero";
import Compare from "../../../components/Tariffs/Compare";
import TariffCalculator from "../../../components/Tariffs/Calculator";
import FreeDemoForm from "../../../components/Form/FreeDemo";
import * as pageStyles from "./tariff-page.module.css";
import TariffBanner from "../../../components/Tariffs/Banner";

const TariffPage = ({data: {product, attributeGroups}, location}) => {
    const tariffs = product.tariffs.sort(({price: aPrice}, {price: bPrice}) => aPrice - bPrice);
    const {seo, tarrifsBanner} = product;

    const offers = product.tariffs.map((tariff) => {
        return {
            price: tariff.price,
            priceCurrency: "RUB",
            itemCondition: "https://schema.org/UsedCondition",
            availability: "https://schema.org/InStock",
            url: location.href,
            seller: {
                name: tariff.title,
            },
        };
    });

    return (
        <Layout className={classNames([pageStyles.tariffPage, pageStyles[`tariffPage_${product.type}`]])}>
            <Seo seo={seo} />
            <ProductJsonLd name={product.title} description={product.description} brand="ITSM 365" offers={offers} />
            <TariffHero type={product.slug} freeDays={product.freeDaysCount} tariffs={tariffs} />
            {tarrifsBanner && <TariffBanner banners={tarrifsBanner} />}
            <Compare attributeGroups={attributeGroups} tariffs={tariffs} />
            <TariffCalculator data={product.calculator[0]} type={product.type} />
            <FreeDemoForm />
        </Layout>
    );
};

export const pageQuery = graphql`
    query ($id: String) {
        product: strapiProducts(id: {eq: $id}) {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            type
            slug
            freeDaysCount
            calculator
            shortTitle
            shortDescription: short_description
            tarrifsBanner {
                desktop {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
                laptop {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
                mobile {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            tariffs {
                id
                title
                description
                price
                freeLicenseCount
                isPopular
                discount {
                    enabled
                    text
                }
                tariffAttributes {
                    id
                    description
                    note
                    isAvailable
                    attribute {
                        id
                        group
                    }
                }
            }
        }
        attributeGroups: allStrapiAttributeGroups(sort: {fields: sortOrder, order: ASC}) {
            edges {
                node {
                    id: strapiId
                    title
                    showTitle
                    ownedAttributes {
                        id
                        title
                        hint
                    }
                }
            }
        }
    }
`;

export default TariffPage;
