import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import {pluralize} from "../../utils/number";
import Section from "../Layout/Section";
import TariffCard from "./Card";
import * as heroStyles from "./hero.module.css";

const TariffHero = ({type, freeDays, tariffs}) => {
    return (
        <Section>
            <div className={heroStyles.tariffHero}>
                {type === "support" && (
                    <>
                        <StaticImage
                            src="../../assets/images/tariffs/support/hero/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/support/hero/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/support/hero/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_mobile}`}
                        />
                    </>
                )}
                {type === "customer" && (
                    <>
                        <StaticImage
                            src="../../assets/images/tariffs/customer/hero/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/customer/hero/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/customer/hero/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_mobile}`}
                        />
                    </>
                )}
                {type === "outsource" && (
                    <>
                        <StaticImage
                            src="../../assets/images/tariffs/outsource/hero/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/outsource/hero/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/tariffs/outsource/hero/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            className={`${heroStyles.tariffHero__blur} ${heroStyles.tariffHero__blur_mobile}`}
                        />
                    </>
                )}
                <h1 className={heroStyles.tariffHero__title}>
                    Воспользуйтесь <span className={heroStyles.tariffHero__product_text}>скидкой 20%</span> на
                    специальных условиях
                </h1>
                <p className={heroStyles.tariffHero__desc}>
                    Выберите подходящий тариф для продукта{" "}
                    <span className={heroStyles.tariffHero__product_name}>ITSM 365.{type}</span>
                </p>
                <p className={heroStyles.tariffHero__desc}>
                    {pluralize(freeDays, ["%d день", "%d дня", "%d дней"])} тестирования бесплатно
                </p>
                <div className={heroStyles.tariffHero__tariffList}>
                    {tariffs.map((tariff) => (
                        <TariffCard key={tariff.id} tariff={tariff} />
                    ))}
                </div>
            </div>
        </Section>
    );
};

export default TariffHero;
